
.custom-nav {
  display: none;
}

.navbar-menu-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-menu-list-items {
  border-bottom: 1px solid black;
  padding: 10px 0 10px 20px;
  margin: 0;
}

.navbar-menu-list-items:hover,
.dropdown:hover {
  cursor: pointer;
}

.dropdown {
  margin: 0;
}

.dropdown span {
  /* padding: 10px 0 0 20px; */
  display: none;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0px;
}

.dropdown-menu-list-items {
  margin: 0;
  padding: 10px 0 10px 20px;
  border-bottom: 1px solid black;
}

@media screen and (min-width: 769px) {
  .custom-nav {
    display: block;
  }

  .navbar-menu-list {
    flex-direction: row;
    gap: 20px;
  }

  .navbar-menu-list-items {
    padding: 0;
    border: none;
    font-weight: bold;
  }

  .dropdown-menu-list-items {
    margin: 0;
    padding: 20px;
    border-bottom: 1px solid black;
  }

  .servicesDropDown {
    background: whitesmoke;
    position: absolute;
    top: 47px;
  }

  .dropdown-menu-list-items {
    margin: 0;
    padding: 20px;
    border-bottom: 1px solid black;
  }

  .dropdown span {
    display: block;
    padding: 0;
    font-weight: bold;
  }
}
.a-custom {
  color: #4f7344;
  text-decoration: none;
} 
@media (max-width: 768px) {
.a-custom {
  color: #000;
  text-decoration: none;
} 
}