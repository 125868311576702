.faq-container {
    padding: 2rem;
    text-align: center;
    background-color: #f5f5f5;
  }
  
  .faq-list {
    list-style: none;
    padding: 0;
  }
  
  .faq-question {
    background: #fff;;
    border: 1px solid #000;
    cursor: pointer;
    /* padding: 10px; */
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-answer {
    background: #fff;
    display: none;
    padding: 10px;
    text-align: left;
    transition: max-height 0.2s;
  }
  
  .faq-question.open {
    color: #fff;
  }
  
  .faq-answer.open {
    display: block;
  }
  @media(max-width:767px){
     .faq-question, .faq-answer {
      font-size: 10px;
     }
  }