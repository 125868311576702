/* AboutUs.css */

.about-us-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 10px;
  }
  
  .about-header {
    text-align: center;
    /* padding: 20px 0; */
  }
  
  .about-header h1 {
    font-size: 36px;
    margin-bottom: 10px;
    color: #4f7344;
  }
  
  .about-header p {
    font-size: 16px;
  }
  .about-content h2 {
    font-size: 28px;
    margin-top: 20px;
    color: #333;
    text-align: center;
  }
  
  .about-content p {
    font-size: 16px;
    margin-left: 62px;
    margin-right: 62px;
    margin-bottom: 20px;
    /* color: #555; */
  }
  
  .about-content ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-top: 10px;
    font-size: 16px;

    /* color: #555; */
  }
  
  .about-content li {
    font-size: 16px;
    margin-bottom: 10px;
    /* color: #555; */
  }
  @media (max-width: 768px) {
    .about-content p{
      margin: 0;
    }
  }