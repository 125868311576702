/* styles.css */
/* CSS for Skeleton Loading */
.skeleton-slider {
  display: flex;
  overflow: hidden;
}

.skeleton-slide {
  flex: 0 0 auto;
  width: 100px; /* Set the width of your image container */
  margin: 8px;
  background-color: #ddd; /* Set a background color for the placeholder */
}

/* Actual Image Styling */
.loaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Adjust based on your image requirements */
}

.error-message {
  color: red !important;
  margin-top: 5px; /* Adjust this value for spacing */
  font-size: 14px !important; /* Adjust this value for font size */
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}
.notfoundimage {
  text-align: center;
}
.notfoundimage button {
  font-size: 22px;
  display: flex;
  left:45%;
  height: 45px;
  border-radius: 15px;
  position: relative;
  background: #4f7344;
  cursor: pointer;
}

/*header */
header {
  position: sticky;
  top: 0;
  color: #000;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  margin-left: 20px;
}

.header nav ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}
.img-logo{
  width: 10%;
}
.logo {
  font-style: italic;
  font-family: ui-sans-serif;
  color: #4f7344;
  /* padding-left: 5%; */
}

.header nav ul li {
  display: inline-block;
  margin-right: 20px;
}

.header nav ul li a {
  text-decoration: none;
  color: #fff;
}

/*footer*/
.logo-footer {
  font-size: 55px !important;
  position: relative;
  align-items: center;
  display: flex;
}

.footer-bold {
  font-weight: bold;
}

footer {
  background-color: #EFB001;
  color: #000;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.footer-left {
  display: flex;
}

.footer-left .logo {
  font-size: 24px;
  margin-right: 20px;
}

.footer-left .reach-us {
  margin-right: 40px; /* Add some spacing between the logo and "Reach Us" content */
}

/*homepage*/
.homepage {
  font-family: Arial, sans-serif;
}
.hero {
  top:0;
  background-size: cover;
  background-position: center;
  background-image: url("../public/customer-service-gifs-hero.jpg");
  height:800px;
}
.hero-bg h1 {
  color: #4f7344;
    font-size: 100px;
    text-align: center;
    margin: 0;
}
.vision-img {
  width: 18%;
}
.global_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 75px;
  background-color: white;
  z-index: 9;
  border-bottom: 1px solid gray;
}
.global_header h1 {
  margin: 0;
}
.hamburger-menu {
  display: none;
}
.leftDrawer,
.rightDrawer {
  display: none;
}
.kebab-menu {
  display: none;
}
.social-menu {
  display: none;
}
.social-icons1 .social-icons2 {
  display: flex;
  flex-direction: column;
}

.social-icons-list {
  list-style: none;
  display: flex;
}

.social-icons-list-items {
  padding: 0 0.5rem;
}

/*WhatWeDo*/
.what-we-do {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
}

.service:hover .what-we-do-card {
  transform: rotateY(180deg) !important;
}

.row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.service {
  perspective: 1000px;
  margin: 1rem;
  cursor: pointer;
}

.card img {
  object-fit: cover;
}

.what-we-do-card {
  display: flex;
  width: 100%;
  height: 250px !important;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.what-we-do-card-front,
.what-we-do-card-back {
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #ffffff;
}

.what-we-do-card-front {
  transform: rotateY(0deg);
}

.what-we-do-card-back {
  transform: rotateY(180deg);
  background-color: #4f7344;
  position: absolute;
  top: 0;
}

.what-we-do-card h4 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.what-we-do-card p {
  margin: 0;
}

.what-we-do-card a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
  transition: color 0.3s;
}

.what-we-do-card a:hover {
  color: #0056b3;
}

.what-we-do-card-front {
  padding: 1rem;
}

.what-we-do-card-back {
  width:  100%;
  height: 100%;
}

.what-we-do-card-front img {
  width: 100%;
  height:  100%;
}

.hexagon-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

/* vision and mission */
.Vision-container {
  background-color: #f8f8f8;
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.Vision-card {
  flex: 1;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.Vision-card h2 {
  font-size: 16px;
  margin-bottom: 10px;
}

.Vision-card p {
  font-size: 16px;
  line-height: 1.6;
}

/*social icons */
.social-links {
  /* margin-top: 38px; */
  display: flex; /* Use flexbox to align icons horizontally */
  /* padding-right: 5%; */
}

.social-links a {
  display: inline-block;
  margin-right: 20px; /* Use a fixed pixel value for consistent spacing */
  color: #fff;
  background-color: #007bff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  font-size: 10px;
}

.social-links a.facebook {
  background-color: #3b5998;
}

.social-links a.twitter {
  background-color: #1da1f2;
}

.social-links a.instagram {
  background-color: #e4405f;
}

.social-links a.linkedin {
  background-color: #0077b5;
}
/* ContactUs.css */
.contact-us-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.contact-us-container h1 {
  text-align: center;
  margin-bottom: 20px;
  font-style: italic;
  text-decoration: underline;
  font-family: Amita;
  color: #4f7344;
}

.card-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
}

.card {
  background-color: lightgray;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  align-items: center;
  /* margin-right: 20px; */
}

.icon {
  font-size: 24px;
  margin-right: 10px;
}

.card-content {
  flex: 1;
  font-size: 25px;
}

.card-content p {
  margin: 0;
  text-align: center;
}

.card-content a {
  display: block;
  margin-top: 10px;
  text-align: center;
}

#message {
  width: -webkit-fill-available;
}

/* Style for the FontAwesomeIcon elements */
.icon {
  text-align: center;
  /* font-size: 24px; */
}
.fa-icon {
  /* You can adjust the size as per your preference */
  width: 50px;
  height: 50px;
  /* line-height: 50px; */
  /* text-align: center !important; */
  border-radius: 50%;
  font-size: 24px;
  display: inline-block;
  margin-right: 20px; /* Use a fixed pixel value for consistent spacing */
}

/* Colors for the icons */
.email-icon {
  color: #ff5722; /* Red color */
}

.phone-icon {
  color: #03a9f4; /* Blue color */
  /* background-color: #007bff; */
}

.clock-icon {
  color: #4caf50; /* Green color */
}
.card:hover {
  background-color: white;
}

.contact-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.contact-form h2 {
  text-align: center;
}

.contact-form p {
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
select {
  width: 90% !important;
  /* padding: 10px; */
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Contact Form Styles */
.contact-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: lightgray;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-form-container h2 {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  font-style: italic;
  margin-bottom: 20px;
  color: #4f7344;
}

.contact-form-container p {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  font-style: italic;
  margin-bottom: 20px;
  /* color: #4f7344; */
  color: #555;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.form-group textarea {
  resize: vertical;
}

.form-group button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

.form-group button:hover {
  background-color: #0056b3;
}
.social a {
  display: inline-block;
  margin-right: 10px;
  color: #fff;
  background-color: #007bff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
}
.services-servicespage {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

.service-card-servicespage {
  background-color: #2d2d31;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  color: #ffffff;
}
.services-container-servicespage {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #000;
}
.services-banner h1 {
  font-size: 100px;
  color: #ffffff;
}
.text-content {
  display: flex;
  flex-direction: row;
}
.text-content h1 {
  text-align: left;
}

.social-menu {
  display: none;
}

.social-icons1 .social-icons2 {
  display: flex;
  flex-direction: column;
}
/* VirtualAssistant.css */

/* Service Page Container */
.Va-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.va-header {
  text-align: center;
  position: unset;
  padding: 0;
}

.va-header h1 {
  margin: 0;
}
.va-header p{
  font-size: 16px;
}
.main-va-div {
  display: flex;
  gap: 20px;
  margin-top: 23px;
  justify-content: space-around;
  flex-direction: row-reverse;
}

.significanceVa,
.service-offerings li{
  font-size: 16px;
  line-height: 1.5;
  padding-left: 30px;
}

.service-offerings-content li{
  font-size: 15px;
  line-height: 1.5;
}
/* Page Header */
.Va-page header {
  display: block;
}

/* Introduction Section */
.Va-page .introduction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.homepage-contact{
  justify-content: center;
  display: flex;
  background-color: #EFB001;
  color: #000;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 5% 35% 0px 40%;
  font-weight: bold;
  width: 15%;
}
/* Contact Section */
.Va-page .contact a {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  font-size: 1.2rem;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  background-color: #0056b3;
}
.Va-page .contact p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}
.va-card-container{
  padding: auto;
  width: 150px !important;
}
.slider {
  text-align: center;
}

.va-card-container h4 {
  margin: 0;
  top: 200px;
}
/* Style for the fixed block on the right */
.rightDiv {
  font-size: 16px;
  width: 75%;
  height: 100%;
  background-color: #EFB001;
  padding: 20px 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  position: relative;
}

.rightDiv h2 {
  margin-top: 0px;
  text-align: center;
}

.serviceList {
  list-style: none;
}

.serviceListItems {
  margin: 0;
  padding: 5px 0;
}

.contact {
  padding: 0 30px;
}

.tech-image{
  object-fit: cover;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
/* Why Choose Us Page */
.why-choose-us {
  /* background-color: #; */
  padding: 2rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.why-choose-us h2 {
  font-size: 24px;
  margin-bottom: 1rem;
  text-align: center;
  color: #4f7344;
}

.choose-us-content {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.choose-us-item {
  flex: 1;
  text-align: center;
  padding: 1.5rem;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.choose-us-item:hover {
  transform: scale(1.05);
  background-color: #EFB001;
  box-shadow: 0px 2px 4px #00bf63;
}

.choose-us-item img {
  width: 50%;
  height: 50%;
  margin-bottom: 1rem;
}

.choose-us-item h3 {
  font-size: 18px;
  margin-bottom: 0.5rem;
  /* color: #4f7344; */
}

.choose-us-item p {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

/*Pricing */
.price-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

#blueTheme {
  background-color: #4f7344;
  box-shadow: 0 4px 8px rgba(42, 72, 92, 0.1);
}

#blueTheme h3 {
  color: white;
}

#blueTheme > div > button {
  background-color: white;
  color: #000000;
}

.Price-card {
  background-color: #EFB001;
  width: 30%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease,
    background-color 0.2s ease;
}

.price-card-header {
  height: 15%;
}

.price-card-header h3 {
  font-size: 24px;
  padding: 10px 0;
  margin: 0;
  color: #4f7344;
}

.price-card-header p {
  font-size: 16px;
  margin: 0;
  color: #333;
}

.services-bullets {
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  align-items: center;
}

.Price-card .features ul {
  list-style: none;
  padding: 0;
}

.Price-card .features li {
  margin: 10px 0;
}

.price-btn {
  background-color: #007acc;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
  width: 50%;
}

.price-btn:hover {
  background-color: #005eae;
}

/* Media Query */

@media (max-width: 768px) {
  .img-logo{
    width: 50%;
  }
  .homepage-contact {
    width: 20%;
  }
  .tech-image{
    width: 75px;
    height: 75px;
  }
  .hero-bg h2{
    font-size: 15px;
    margin-top: 0px;
  }
  .follow-us h3,.follow-us p{
    font-size: 10px;
  }
  .hero h1 {
    font-size: 50px;
  }
  .hero{
    height: 350px;
  }
.price-btn{
  margin-bottom:30px;
}
.what-we-do-card{
  justify-content: center;
}  
 .hamburger-menu {
    display: block;
  }
  .leftDrawer {
    display: block;
    height: 100%;
    width: 50%;
    max-width: 200px;
    position: fixed;
    top: 50px;
    left: 0;
    padding-top: 50px;
    z-index: 8;
    background: rgb(236, 234, 234);
  }
  .kebab-menu {
    display: block;
  }

  .rightDrawer {
    display: block;
    height: 20%;
    width: 25%;
    max-width: 75px;
    position: fixed;
    top: 50px;
    right: 0px;
    z-index: 8;
    padding-top: 25px;
    background: rgb(236, 234, 234);
  }

  .social-icons1 {
    display: none;
  }

  .social-icons-list {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .social-icons-list-items {
    padding: 5px 0 5px 0;
    margin: 0;
  }

  .hero-content {
    flex-direction: column-reverse;
  }

  /* <HomePage /> CSS for smaller screens */
  .hero-content {
    flex-direction: column-reverse;
  }

  .Price-card {
    width: 320px;
  }

  .price-card-header p {
    margin-bottom: 20px;
  }

  /* <Vision /> CSS for smaller screens */

  .services-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .service-card {
    margin: 0 10px;
  }

  .main-va-div {
    flex-direction: column-reverse;
  }

  .rightDiv {
    width: 100%;
  }

  /* <WhatWeDo /> CSS for smaller screens */

  .what-we-do {
    flex-direction: column;
    gap: 10px;
  }

  .service {
    width: 100%;
    padding: 0px;
  }

  /* <Footer /> CSS for smaller screens */

  .footer-social-icons {
    transform: rotate(270deg);
  }

  .footer-social-icons .social-icons-list-items {
    transform: rotate(90deg);
  }
  .social-menu {
    display: block;
  }

  .social-icons2 {
    display: flex;
    flex-direction: row;
  }

  .social-icons1 {
    display: none;
  }
  .logo-footer {
    font-size: 45px !important;
  }
  .hero p {
    font-size: 16px;
  }

  .hero button {
    font-size: 12px;
  }
  .menu-icon {
    display: flex !important;
    left: 2px;
    position: fixed;
    top: 36px;
    margin-left: 10px;
  }
  .logo h1 {
    margin-left: 10px;
    font-size: 30px;
  }
  .menu {
    display: none;
    list-style: none;
    padding: 0;
    text-align: center;
  }
  .social-dot-icon {
    display: flex !important;
    right: 10px;
    position: absolute;
    top: 36px;
    color: #4f7344;
    margin-right: 20px; /* Adjust as needed */
    font-size: 24px;
    cursor: pointer;
  }
  .social {
    display: none;
    align-items: center;
    margin-right: 20px;
  }
  .social-links a {
    margin: 0;
  }

  .social-icons2 {
    justify-content: center;
  }

  .menu-icon {
    display: none;
    font-size: 24px;
    cursor: pointer;
  }
  .social-dot-icon {
    display: none;
  }

  .social-links {
    display: flex;
    align-items: center;
  }

  .social-dot-icon {
    position: absolute;
    top: 36px;
    right: 10px;
    color: #4f7344;
    font-size: 24px;
    cursor: pointer;
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .fa-icon {
    margin: 0;
  }

  .card-content {
    font-size: 18px;
  }
  .choose-us-content {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .Vision-container {
    flex-direction: column;
  }
}

/* CSS for even smaller screen size to adjust footer */

@media (max-width: 654px) {
  .hero-bg h2{
    font-size: 10px;
    margin-top: 0px;
  }
  .hero h1 {
    font-size: 24px;
  }
  .hero {
    height: 175px;
  }
  .footer-left {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* CSS for more smaller screen size to adjust Socail Icons on footer */

@media (max-width: 425px) {
  .footer-social-icons {
    transform: rotate(0deg);
  }

  .footer-social-icons .social-icons-list-items {
    transform: rotate(0deg);
  }
  .google-iframe {
    width: 100%;
  }
  .milestones {
    flex-direction: column;
    gap: 10px;
  }
  .card-container {
    flex-direction: column;
  }

}

@media (max-width: 376px) {
  .logo-footer {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 600px) {
  .contact-form-container {
    padding: 10px;
  }
  .google-iframe {
    width: 100%;
  }
  .card-container {
    flex-direction: column;
  }
}

.custom-nav {
  display: none;
}

.navbar-menu-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-menu-list-items {
  border-bottom: 1px solid black;
  padding: 10px 0 10px 20px;
  margin: 0;
}

.navbar-menu-list-items:hover,
.dropdown:hover {
  cursor: pointer;
}

.dropdown span {
  padding: 10px 0 0 20px;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0px;
}

.dropdown-menu-list-items {
  margin: 0;
  padding: 10px 0 10px 20px;
  border-bottom: 1px solid black;
}

@media screen and (min-width: 769px) {
  .custom-nav {
    display: block;
  }

  .navbar-menu-list {
    flex-direction: row;
    gap: 20px;
  }

  .navbar-menu-list-items {
    padding: 0;
    border: none;
    font-weight: bold;
  }

  .dropdown-menu-list-items {
    margin: 0;
    padding: 20px;
    border-bottom: 1px solid black;
  }

  .servicesDropDown {
    background: grey;
    position: absolute;
    /* top: 60px; */
    top: 47px; 
  }

  .dropdown-menu-list-items {
    margin: 0;
    padding: 20px;
    border-bottom: 1px solid black;
  }

  .dropdown span {
    padding: 0;
    font-weight: bold;
  }
}

.tech-we-use {
  display: flex;
  justify-content: space-around;
}

.bouncingDiv {
  text-align: center;
  margin: 10px;
}

.bouncingDiv img {
  cursor: pointer;
}

.bouncingDiv.bounce {
  animation: bounce 2.5s ease infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
}
